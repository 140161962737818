<template>
  <div class="c-bg-primary-200">
    <div class="c-container ui-pt-8">
      <!-- Logo e stepper -->
      <div class="c-row ui-mb-4">
        <!-- Logo Estratégia -->
        <div class="c-col-2 sm:c-col-2 md:c-col-2 ui-mb-4">
          <estrategia-logo />
        </div>

        <!-- Stepper -->
        <div class="c-col-2 sm:c-col ui-mb-4">
          <p-stepper />
        </div>
      </div>

      <div class="c-row ui-items-center">
        <!-- Título -->
        <div class="c-col-full sm:c-col-3 md:c-col-6 ui-mb-4">
          <span class="c-text-white c-text-h3">
            {{ title }}
          </span>
        </div>

        <!-- Ilustração -->
        <div class="c-col-full sm:c-col-3 md:c-col c-d-flex ui-justify-end">
          <img
            v-if="illustrationPath"
            :src="illustrationPath"
            alt="Illustration"
            style="max-height: 196px; max-width: 60%;"
            class="ui-mb-4"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PStepper from '@/components/p-stepper';
import EstrategiaLogo from '@/components/estrategia-logo';

export default {
  name: 'Header',
  components: {
    PStepper,
    EstrategiaLogo,
  },
  data: () => ({
    title: '',
    illustrationPath: '',
  }),
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.title = route.meta.headerTitle;
        this.illustrationPath = route.meta.headerIllustration;
      },
    },
  },
};
</script>
