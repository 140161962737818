<template>
  <div class="c-bg-gray-100">
    <Header />

    <div class="c-container h-full">
      <div class="c-row c-horizontal-center h-full">
        <div class="c-col-2 sm:c-col-6 md:c-col-10 lg:c-col-8">
          <div class="ui-my-8">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './header';

export default {
  components: { Header },
};
</script>
